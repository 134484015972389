.header-sub-text {
    color: black;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: -0.4px;
}
.header-text {
    color: black;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 44px;
    font-style: normal;
    font-weight: bold;
    line-height: 48px; /* 100% */
    letter-spacing: -0.8px;
}
