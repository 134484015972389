#root {
    overflow-y: auto;
    height: 100vh;
    color: #252525;
    font-family: Roboto;
}

@font-face {
    font-family: "Roboto";
    src: url("../src/Assets/Fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Garnett Semibold";
    src: url("../src/Assets/Fonts/Garnett-Semibold.ttf");
}
@font-face {
    font-family: "Garnett Light";
    src: url("../src/Assets/Fonts/Garnett-Light.ttf");
}
@font-face {
    font-family: "Garnett Regular";
    src: url("../src/Assets/Fonts/Garnett-Regular.ttf");
}
@font-face {
    font-family: "Garnett Medium";
    src: url("../src/Assets/Fonts/Garnett-Medium.ttf");
}
