.title {
    color: #252525;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;

    letter-spacing: -0.4px;
}
.subTitle {
    align-self: stretch;
    color: #696969;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    letter-spacing: -0.4px;
}
.info {
    width: 90vw;
    color: #696969;
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.2px;
}

.headerSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    align-self: stretch;
}
.footerSection {
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.brokerInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}
.name {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.name span {
    color: #74a5aa;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    letter-spacing: -0.4px;
}

.note {
    display: flex;
    padding: 12px 12px 24px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 0px 8px 8px 8px;
    background: #74a5aa;
}
.noteText {
    white-space: break-spaces;
    color: #fff;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    letter-spacing: -0.4px;
    align-self: stretch;
}
.contactInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}
.innerContact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
.innerContact div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 768px) {
    .innerContact {
        flex-direction: column;
    }
    .footerSection {
        padding: 0px;
    }
    .contactInfo {
        flex-direction: column;
    }
}
@media only screen and (min-width: 2000px) {
    .footerSection {
        padding: 0px 300px;
    }
}
.contactImg {
    color: #b0cccf;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;

    letter-spacing: -0.4px;
}

.contactText {
    color: #474747;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    letter-spacing: -0.4px;
}
.contactText a {
    color: #397f86;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
}

.tableSection {
    color: #696969;
    text-align: right;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    letter-spacing: -0.2px;
    align-self: stretch;
}
.tableDetail {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 8px;
    justify-content: space-around;
}

.msgToMngr .btn {
    color: #397f86;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
    border-radius: 4px;
    border: 1px solid #397f86;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
}

.circle {
    /* padding: 10px 15px 10px 15px; */
    height: 40px;
    width: 40px;
    background-color: #397f86;
    /* Circle color */
    color: white;
    /* Text color */
    border-radius: 60%;
    /* Makes it round */
    display: flex;
    align-items: center;
    /* Vertical alignment */
    justify-content: center;
    /* Horizontal alignment */
    font-size: 15px;
    /* Font style */
    user-select: none;
    /* Prevents text selection */
}
