.hide {
    display: none;
}
.navbar {
    font-family: "Garnett Semibold", sans-serif !important;
    color: #161893 !important;
}
/* @media only screen and (max-width: 1850px) {
    .navbar {
        color: #161893 !important;
    }
} */
