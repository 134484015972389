#priceConfig .clearfix {
    width: 100%;
    color: white;
    background-color: #74a5aa;
}

.pricePaginate {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.pricePaginate li {
    border: solid 1px gray;
    padding: 5px 10px 5px 10px;
    background-color: white;
    color: black;
}
@media only screen and (max-width: 600px) {
    .pricePaginate li {
        border: solid 1px gray;
        padding: 5px;
    }
    #priceConfig table,
    #priceConfig .clearfix {
        width: 95%;
    }
}
.pricePaginate li:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.pricePaginate li:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.pricePaginate__btn--disable {
    color: gray;
    cursor: not-allowed;
}
.pricePaginate__btn--active {
    color: #397f86;
    font-weight: bolder;
}

#priceConfig {
    width: 90vw;
    border-radius: 8px;
}
.priceConfigBody table,
#priceConfigEmptyBody {
    width: 100%;
}
.priceConfigBody,
#priceConfigEmptyBody {
    padding: 12px;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #d7d1d1;
    background: #fff;
    overflow: auto;
}

tr td {
    text-align: left;
    padding: 12px;
    gap: 8px;
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    align-self: stretch;
    white-space: nowrap;
}

tr:nth-child(1) th:first-child,
#priceConfigEmptyBody table tbody tr:nth-child(1) td:first-child {
    width: 70%;
}
tr:nth-child(1) th,
#priceConfigEmptyBody table tbody tr:nth-child(1) td {
    background: white !important;
    text-align: left;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    height: 24px;
    color: #252525;
    white-space: nowrap;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
}
tr td:nth-child(2) {
    color: #474747;
}
tr:nth-child(1) th,
tr td:nth-child(2),
#priceConfigEmptyBody table tbody tr:nth-child(1) td {
    font-style: normal;
    font-weight: 800;
}

tr:nth-child(odd),
#priceConfigEmptyBody table tbody tr:nth-child(2) {
    background: #f3f1f1;
}
#emptyBodyText {
    text-align: center;
}
.fbLink {
    color: #397f86;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    letter-spacing: -0.4px;
    text-decoration-line: underline;
}
tr td:first-child {
    white-space: break-spaces;
}
