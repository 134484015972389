.tokenExpireDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 0 0;
    align-self: stretch;
    display: flex;
    /* min-height: 40vh; */
    padding: 62px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #d7d1d1;
    background: #f3f1f1;
}
.tokenExpireText {
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #c52a1a;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
}
.expiryDate {
    color: #c52a1a;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
}
