.layout {
    /* display: flex; */
    padding: 32px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #d7d1d1;
    background-color: #fff;
    height: auto;

    min-height: 100vh;
    display: flex;
    justify-content: top;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    flex: 1;
}
